<template>
  <div class="image-container">
    <div
      class="before"
      :style="{ width: `${widthPercentage}%` }"
    >
      <NuxtImg
        class="image"
        :src="beforeImage"
        alt="before"
      />
    </div>

    <NuxtImg
      class="image image--after"
      :src="afterImage"
      alt="after"
    />
    <input
      class="range"
      type="range"
      min="0"
      max="100"
      v-model="widthPercentage"
    >

    <div class="slider-indicator">
      <span class="indicator indicator--left">
        <v-icon size="30px">
          mdi-chevron-left
        </v-icon>

      </span>
      <span class="indicator indicator--right">
        <v-icon size="30px">
          mdi-chevron-right
        </v-icon>
      </span>
    </div>

    <span
      v-if="isLandingPage && widthPercentage > 10"
      class="before-label"
    >
      Before

    </span>
    <span
      v-if="isLandingPage && widthPercentage < 90"
      class="after-label"
    >
      After
    </span>
  </div>
</template>

<script lang="ts" setup>
const { beforeImage, afterImage } = defineProps({
  beforeImage: {
    type: String,
    required: true,
    default: undefined,
  },
  afterImage: {
    type: String,
    required: true,
    default: undefined,
  },
  isLandingPage: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const widthPercentage = ref(50)
</script>

<style lang="scss" scoped>
.image-container {
  height: 100%;
  margin: 0 auto;
  max-width: 100%;
  width: fit-content;
  position: relative;
  overflow-x: hidden;

  > .before {
    height: 100%;
    max-width: 100%;
    overflow: auto;
    resize: horizontal;
    position: absolute;
    border-right: 2px solid #33688a;
  }

  > .range {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    cursor: col-resize;
  }

  > .slider-indicator {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    transform: translateY(-50%);
    pointer-events: none;
    left: v-bind('`${(widthPercentage - 50)}%`');

    .indicator {
      border: none;
      font-size: 2rem;
      padding: 0.5rem;
      user-select: none;
      color: #33688a;
      &--left {
        margin-left: 0.5rem;
      }

      &--right {
        margin-right: 0.5rem;
      }
    }
  }

  > .before-label,
  > .after-label {
    position: absolute;
    top: 50%;
    background: rgba(247, 247, 247, 0.75);
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 300;
  }

  > .before-label {
    left: 10px;
  }

  > .after-label {
    right: 10px;
  }
}

.image {
  height: 100%;
  display: block;
  max-width: none;

  &--after {
    max-width: 100%;
  }
}
</style>
